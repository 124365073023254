#loginform {
    padding: 70px;
}

.navbarbg{
    background-color: #ffffff !important;
}

.navbar-header{
    background-color: #13396a !important;
}

.header-user-text {
    color: #15102e;
}

.header-light-dark-mode{
    margin-top: 25px;
    margin-right: 30px;
}

.light-mode-label {
    color: #238da7;
}

.dark-mode-label {
    color: #272e50;
}

.header-profile-name{
    padding-top: 18px;
    color: #15102e;
}

.header-profile-arrow{
    font-size: 14px;
    margin-top: 10px;
}

.header-profile-popup-color{
    background-color: #13396a;
}

.profile-dd{
    display: none;
}

#sidebarnav {
    background-color: #13396a !important;
    padding: 0px;
    padding-top: 50px !important;
}

#sidebarnav .active  {
    background: none !important;
    box-shadow: none;
    background-color: transparent !important;
    color: #32d6d0 !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link{
    color: #80b2d3 !important;
    padding: 4px 0 4px 4px !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover{
  color: #32d6d0 !important;
}

#sidebarnav .sidebar-item{
    font-size: 14px;
}


.rectangle {
    width: 100%;
    height: 70px;
    position: relative;
    background-color: #13396a;
}

.rectangle-round-down {
    width: 100%;
    height: 70px;
    background-color: #e0ebf8;
    border-radius:  0px 0px 0px 70px;
}

[dir="rtl"] .rectangle-round-down {
    border-radius:  0px 0px 70px 0px;
}

.navbarbg {
    background-color: #e0ebf8 !important;
    height: 70px;
}

.round-corner-up-bg {
    background-color: #13396a !important;
    width: 70px;
}

.round-corner-up {
    height: 70px;
    background-color: #e0ebf8;
    border-radius:  70px 0px 0px 0px;


}

[dir="rtl"] .round-corner-up {
    border-radius:  0px 70px 0px  0px !important;
}

.navbar-nav {
    background-color: #e0ebf8;
}

.footer {
    padding: 0px;
    margin: 0px;
    margin-bottom: -45px;
}

.image-modal-card {
    padding: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-button{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #164669;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    font-size: 14px;
    color: #164669;
    font-weight: bold;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
}

.ReactTable.-striped .rt-tr.-odd {
    background-color: #f6f8fc !important;

}

.ReactTable .rt-tbody .rt-td {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    color: #262d3c;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
}


.card {
    border-radius: 4px;
    border: solid 1.1px #e6e5f2;
    box-shadow: none;
}

.table-title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #272e50;
}
.device-sidebar {
    width: 500px;
    right: -500px;

}

.device-control {
    width: 100%;
}

.device-control .input-label {
    font-size: 18px;
    font-weight: bold;
}

.device-control .slider-number {
    font-weight: bold;
}

.device-class-card {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #262d3c;
}

.device-class-card .logo {
    width: 86px;
    margin-bottom: 20px;
}

.button-big {
    width: 180px;
    height: 45px;
    margin: 20px 0 0;
    border-radius: 4px;
    border: solid 1px #1b7389;
    color: #1b7389;
    background-color: #ffffff;
}

.device-class-card .devices-in-project {
    padding-top: 5px;
    font-family: Heebo;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: center;
    color: #262d3c;
}
 
.table-loading {
    position: absolute;
    top: 100px;
    left: 100px;
}
.ReactTable.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.1) !important;
}
.redText{
    color:red;
}
.deviceSearchInput{
    margin-left: 5px;
    width:210px
}